import React from "react";
import Banner from "../components/Banner";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Cta from "../components/service/Cta";
import ServiceTab from "../components/ServiceTab";
import Clients from "../components/service/Clients";
import Testimonials from "../components/Testimonials";
import ProjectSection from "../components/ProjectSection";
import Faq from "../components/Faq";
// import KnowMoreSection from "../components/KnowMoreSection";
import { Seo } from "../components/seo";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";

const ServiceDetail = () => {
  const banner = {
    title: `UI UX Designing Company`,
    content:
      "A leading UI UX designing company transforming vision into captivating designs for seamless user experience.",
  };

  const commonContent6 = {
    title: "Get Custom <span class='h1-span'>UI UX Designing Services</span>",
    para: "Our UI UX design solutions focus on shaping digital experiences across various industries. We collaborate to understand your target audience, conduct in-depth analysis, and gain insights to offer custom UI UX services for improved business performance.",
    img: ["what-service-image-uiux.webp"],
    imageTitle: "UI UX Design Difference",
    imageAlt: "ui ux design consultant",
    content: [
      {
        title: "Website UI/UX Design",
        text: "We craft user-friendly, visually engaging UI UX web design interfaces prioritizing seamless user experiences.",
      },
      {
        title: "Mobile App UI/UX Design",
        text: "Our mobile UI UX design services, offering responsive, intuitive interfaces, focus on user satisfaction.",
      },
      {
        title: "Dashboard Interface Design",
        text: "Our services optimize dashboard and software interfaces for functionality and user experience.",
      },
      {
        title: "User Experience Audit",
        text: "With our UX audit services, we evaluate existing digital products to identify areas for improvement.",
      },
      {
        title: "Prototyping & Wire Framing",
        text: "Our UI UX solutions help you validate designs by creating prototypes & wireframes for early feedback on functionalities.",
      },
      {
        title: "UI UX Design Consultation",
        text: "We provide tailored UI UX consultation services to align UI/UX designs with your project objectives.",
      },
      {
        title: "SaaS Product Design",
        text: "Our UX design services optimize your SaaS products' engagement, subscription rates, and user satisfaction.",
      }
    ],
  };

  const commonContent = {
    title: "Industry We Empower As <span class='h1-span'>UI UX Designing Company</span>",
    para: "UI UX design services are in demand across industries to create intuitive and user-centric experiences. As UI UX design consultants, we empower these industries to optimize their digital experiences.",
    img: ["who-needs-uiux-user-image.webp"],
    imageTitle: "Design Screens",
    imageAlt: "ui ux designing company",
    content: [
      {
        title: "Digital Product Companies",
        text: "Companies that aim to enhance customer satisfaction, drive engagement, and differentiate their product offerings in a competitive market.",
      },
      {
        title: "SaaS Solution Companies",
        text: "Companies that focus on increasing user adoption by streamlining the interface, prioritizing usability, and effective information architecture.",
      },
      {
        title: "Marketing Agencies",
        text: "Companies who want to optimize the user experience of their digital asset to improve conversion rate, maximize user engagement, and communicate the brand's message.",
      },
      {
        title: "Enterprise",
        text: "Companies that focus on delivering an exceptional user experience along with a smooth organizational flow to fuel business growth.",
      },
    ],
  };

  const commonContent2 = {
    title: "Benefits of Choosing <span class='h1-span'>UI UX Designing Services</span>",
    para: "Partnering with a UI UX design company unlocks enhanced usability, higher conversions, and faster time to market. Their end-to-end UI UX design and development services create intuitive interfaces that captivate your users and drive long-term loyalty.",
    img: ["what-are-benefits-ui-ux-company.webp"],
    imageTitle: "UI UX Screen",
    imageAlt: "ui ux designing services",
    content: [
      {
        title: "Improved User Experience",
        text: "UI UX Services provide an intuitive interface and visually appealing designs, increasing user engagement and seamless interaction.",
      },
      {
        title: "Gain Customer Loyalty",
        text: "UI UX design agency delivers an exceptional user-centric design that prioritizes usability and personalization and fosters long-term loyalty.",
      },
      {
        title: "Get Competitive Advantage",
        text: "A UI UX design company provides intuitive, user-centered UI UX solutions that help you surpass competitors and attain long-term growth.",
      },
      {
        title: "Make Data-driven Decision",
        text: "UI UX agency leverages user research and insights, empowering you to optimize design strategies for enhanced performance.",
      },
    ],
  };
  const commonContent3 = {
    title: "Choose Octet: Your Ultimate <br /> UI UX Designing Company",
    linkText: ``,
    para: "Octet is a leading UI UX Design Company that enhances the digital presence of industries. With our unrivaled expertise and detailed attention, we create UI UX design solutions that perfectly align with your brand vision.",
    img: ["why-opt-for-uiux.webp"],
    imageTitle: "UI UX Project",
    imageAlt: "ux design consulting firm",
    content: [
      {
        title: "Expertise and Experience",
        text: "We have a solid foundation of experience and deep expertise, enabling us to create user-centered designs with a long-lasting impact.",
      },
      {
        title: "Diverse Portfolio",
        text: "Our design portfolio demonstrates the versatility and the ability to offer tailored UI UX solutions that meet varied industrial requirements.",
      },
      {
        title: "Collaborative Approach",
        text: "We collaboratively work with our clients in the design process and seek their input to ensure final designs meet their objectives.",
      },
      {
        title: "Timely Delivery & Efficiency",
        text: "Our efficient design process and effective project management ensure that we meet deadlines without compromising on the quality of the work.",
      },
      {
        title: "Long-Term Partnership",
        text: "We create long-term partnerships with our clients by understanding their business needs and providing ongoing support.",
      },
    ],
  };
  const commonContent4 = {
    title: "Our proven approach to <span class='h1-span'>UI UX Design Solutions</span>",
    para: "Our UI UX solutions blend user research, prototyping, and data-driven design to craft exceptional digital experiences for your customers. As a leading UI UX Designing Company, we deliver tailored interfaces that elevate your brand & drive success.",
    img: ["how-we-conduct-uiux.webp"],
    imageTitle: "UI UX Process",
    imageAlt: "best ui ux design company",
    content: [
      {
        title: "1. Researching Needs",
        text: "We understand the client's goals for developing user-preferred products and research according to users' needs.",
      },
      {
        title: "2. User Flows and Wireframing",
        text: "Based on user research, we develop user flows, create information architecture, and model wireframes.",
      },
      {
        title: "3. Designing and Prototyping",
        text: "We then proceed with the product's visual design to appropriately serve the users' needs.",
      },
      {
        title: "4. Monitoring and Testing",
        text: "At last, we perform usability testing on the prototype before the launch and actively monitor users' feedback.",
      },
    ],
  };
  const commonContent5 = {
    title: "Maximise ROI with Best <span class='h1-span'>UI UX Design Company</span>",
    para: "UI UX design services bring a fresh perspective to business by optimizing the overall functionality of digital products. Investing in a skilled UI UX designing company drives customer loyalty and business growth.",
    content: [
      {
        title: "66% User Willingness",
        text: "Around 66% of customers are willing to pay more for an exceptional user experience that solves their problem.",
      },
      {
        title: "72% Better Word of Mouth",
        text: "Around 72% of users recommend your product to others if they have a positive experience with your product.",
      },
      {
        title: "62% Increased Sales",
        text: "Companies that invest in mobile responsive websites and improve performance on other platforms secure more sales.",
      },
    ],
    desc: "*These data are from public sources; we do not claim to owe these stats.",
  };
  const cta = {
    title: "Optimize your Website <span class='h1-span'>with UI UX Web Design!</span>",
  };
  const cta2 = {
    title: "Start your UI UX Designing <span class='h1-span'>Journey With Us!</span>",
    contactText: "Reach Out",
  };
  const cta3 = {
    title: "Supercharge your UI UX <span class='h1-span'>Designs With Us!</span>",
    contactText: "Reach Out",
  };
  const cta4 = {
    title: "Ready to Elevate UX With <span class='h1-span'>Our UI UX Design Agency?</span>",
  };
  const cta5 = {
    title: "Ready for <span class='h1-span'> UI UX Design Consulting?</span>",
  };
  const tabs = [
    {
      link: "/angularjs-development/",
      text: "Angular Development",
    },
    {
      link: "/branding/",
      text: "Branding",
    },
    {
      link: "/corporate-training-workshop/",
      text: "UI UX Workshops and Training",
    },
    {
      link: "/data-visualisation/",
      text: "Dashboards and Data Visualisation",
    },
    {
      link: "/design-audit/",
      text: "Design Audit ",
    },
    {
      link: "/design-systems/",
      text: "Design System Engineering",
    },
    {
      link: "/digital-transformation/",
      text: "Digital Transformation",
    },
    {
      link: "/product-development/",
      text: "Product Development",
    },
    {
      link: "/rapid-prototyping/",
      text: "Rapid Prototyping",
    },
    {
      link: "/reactjs-development/",
      text: "ReactJS Development",
    },
    {
      link: "/ui-development/",
      text: "UI Development",
    },
    {
      link: "/ui-ux-designing/",
      text: "UI UX Designing",
    },
    {
      link: "/usability-testing/",
      text: "Usability Testing",
    },
    {
      link: "/user-acquisition-consulting/",
      text: "User Acquisition Consultation",
    },
    {
      link: "/user-research/",
      text: "User Research",
    },
    {
      link: "/ux-consulting/",
      text: "UX Consultation",
    },
    {
      link: "/vuejs-development/",
      text: "VueJS Development",
    },
    {
      link: "/wordpress-development/",
      text: "WordPress Development",
    },
  ];
  const projectData = [
    {
      title: "Survey2Connect",
      desc: "A Research Tech Platform",
      Industry: "Enterprise",
      firstAlt: "best ui ux design agency",
      firstTitle: "Survey Creation Screen",
      secondAlt: "top ui ux design agency",
      secondTitle: "Questionnaire for Survey",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/survey2connect/",
      images: ["survey.webp", "survey-2.webp"],
    },
    {
      title: "Mom’s Belief",
      desc: "An E-learning platform",
      Industry: "Enterprise",
      firstAlt: "ui ux design services in india",
      firstTitle: "Landing Page",
      secondAlt: "ui ux design consulting",
      secondTitle: "Program Details",
      WhatWeDid: [
        {
          title: "UX Consultation",
          link: "/ux-consulting/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Usability Testing",
          link: "/usability-testing/",
        },
      ],
      link: "/project/ui-ux-design-moms-belief/",
      images: ["moms-belief.webp", "moms-belief-2.webp"],
    },
  ];
  const Questions = [
    "Can your UI UX design agency redesign an existing product?",
    "Can you provide examples of your completed UI UX design projects",
    "What metrics do you use to measure UI UX design effectiveness?",
    "What tools and software do you use for UI UX design?",
    "What level of involvement is expected from us during the design process?",
    "What is the duration of your UI UX designing process?",
    "How much does hiring your UI UX Design Services cost?",
    "What deliverables can I expect from your UI UX services?",
  ];
  const clientsTitle = "Companies Who Trusted Us For UI UX Design Services ";
  const faqDes = `This section provides comprehensive information and insights to help you make informed decisions about our UI UX design services. However, if you still have any questions, please <a href="/contact-us/">contact us</a>.`;

  const faqData = [
    {
      para: [
        `Yes, as a leading UI UX Designing Agency, we focus on every aspect of UI UX Design, which also involves redesigning an existing product`,
        `We have assisted many clients in revamping their digital products, and we can deliver you a similar service to enhance the overall user experience and solve your existing usability challenges.`,
      ],
    },
    {
      para: [
        `You can look at our completed <a href="/projects/" target="_blank" rel="noreferrer">projects</a> or  <a href="/contact-us/" target="_blank" rel="noreferrer">contact us</a> for more information on our work.`,
        `Our projects span various industries, including mobile apps, websites, software interfaces, etc. We deliver exceptional user experiences through our Mobile App UI UX design services and UI UX web design services to help our clients achieve their business goals.`,
      ],
    },
    {
      para: [
        `We measure UI UX design effectiveness through user satisfaction surveys, feedback, testing, and monitoring task success rate, user engagement metrics, and usability metrics to identify areas for improvement.`,
      ],
    },
    {
      para: [
        `We use various UI UX design software like Figma, Adobe XD, and Sketch for wireframes and prototypes.`,
        `User research tools for UX research include Mixpanel, Pendo, UserTesting, UsabilityHub, and many more.`,
        `We also conduct workshops and interviews to validate design decisions. With these tools, we deliver high-quality UI UX design solutions to clients.`,
      ],
    },
    {
      para: [
        `We encourage open communication and expect participation in transferring knowledge in your domain, clear business goals, and critical decision-making stages, such as reviewing wireframes and prototypes.`,
        `Your timely feedback ensures that our UI UX design reflects your preferences and effectively meets your business objectives.`,
      ],
    },
    {
      para: [
        `The duration of our UI UX designing process can vary depending on the complexity of the project, the scope of work, and our client’s requirements.`,
        `The process generally takes 45 days (6 weeks), but depending on your business needs, we strive to maintain an efficient timeline while ensuring quality work that exceeds your expectations.`,
      ],
    },
    {
      para: [
        `The cost of hiring the best UI UX design services from our UI UX designing company depends on various factors, such as project scope, complexity, and the designer’s experience.`,
        `We offer customized pricing tailored to each client’s needs and budgets. Please <a target="_blank" rel="noreferrer" href="/contact-us/">reach out to us</a> for a detailed discussion and quote.`,
      ],
    },
    {
      para: [
        `We provide a comprehensive set of deliverables, including wireframes, interactive prototypes, UI designs, usability test reports, and design style guides.`,
        `These assets are tailored to your business needs and offer a strong foundation for developing and implementing successful user-centered UI UX design solutions.`,
      ],
    },
  ];
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
              {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [{
                  "@type": "Question",
                  "name": "1. Can your UI UX design agency redesign an existing product?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, as a leading UI UX Designing Agency, we focus on every aspect of UI UX Design, which also involves redesigning an existing product. 
              
              We have assisted many clients in revamping their digital products, and we can deliver you a similar service to enhance the overall user experience and solve your existing usability challenges."
                  }
                },{
                  "@type": "Question",
                  "name": "2. Can you provide examples of your completed UI UX design projects?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, you can look at our completed UI UX projects or contact us for more information on our work. 
              
              Our projects span various industries, including mobile apps, websites, software interfaces, etc. We deliver exceptional user experiences through our Mobile App UI UX design services and UI UX web design services to help our clients achieve their business goals."
                  }
                },{
                  "@type": "Question",
                  "name": "3. What metrics do you use to measure UI UX design effectiveness?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "We measure UI UX design effectiveness through user satisfaction surveys, feedback, testing, and monitoring task success rate, user engagement metrics, and usability metrics to identify areas for improvement."
                  }
                },{
                  "@type": "Question",
                  "name": "4. What tools and software do you use for UI UX design?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "We use various UI UX design software like Figma, Adobe XD, and Sketch for wireframes and prototypes. 
              
              User research tools such as Mixpanel, Pendo, UserTesting, UsabilityHub, and many more for UX research. 
              
              We also do Workshops and interviews to validate design decisions. With these tools, we deliver high-quality UI UX design solutions to clients."
                  }
                },{
                  "@type": "Question",
                  "name": "5. What level of involvement is expected from us during the design process?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "We encourage open communication and expect your participation in transferring knowledge in your domain, clear business goals, and key decision-making stages, such as reviewing wireframes and prototypes. 
              
              Your timely feedback ensures that our UI UX design reflects your preferences and effectively meets your business objectives."
                  }
                },{
                  "@type": "Question",
                  "name": "6. What is the duration of your UI UX designing process?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The duration of our UI UX designing process can vary depending on the complexity of the project, the scope of work, and our client’s requirements. 
              
              Generally, the process takes 45 days (6 weeks), but depending on your business needs, we strive to maintain an efficient timeline while ensuring quality work that exceeds your expectations."
                  }
                },{
                  "@type": "Question",
                  "name": "7. How much does hiring your UI UX Design Services cost?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The cost of hiring the best UI UX design services from our UI UX designing company depends on various factors, such as project scope, complexity, and the designer’s experience. 
              
              We offer customized pricing tailored to each client’s needs and budgets. Please contact us for a detailed discussion and quote."
                  }
                },{
                  "@type": "Question",
                  "name": "8. What deliverables can I expect from your UI UX services?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "We provide a comprehensive set of deliverables, including wireframes, interactive prototypes, UI designs, usability test reports, design style guides, etc. 
              
              These assets are tailored to your business needs and offer a strong foundation for developing and implementing successful user-centered UI UX design solutions."
                  }
                }]
              }              
              `}
        </script>
      </Helmet>
      <Layout>
        <div className="service-detail-page">
          <Banner content={banner} page={"service"} />
          <CommonServiceComponent data={commonContent6} mode={"grey"} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent} mode={"light"} />
          <Cta mode="grey" data={cta4} />
          <CommonServiceComponent data={commonContent2} mode={"dark"} />
          <CommonServiceComponent data={commonContent3} mode={"light"} />
          <Cta data={cta} />
          <Clients title={clientsTitle} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent4} mode={"light"} />
          <Cta mode="grey" data={cta5} />
          <section className="project-section lg:py-[200px] md:py-[120px] py-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
            <div className="container">
              <h2 className="lg:mb-[80px] md:mb-[40px] mb-[30px] font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] tracking-[.01em]">
                Recent UI UX Design: <span className="h1-span">Case Studies</span>
              </h2>
            </div>
            {projectData.map((data, index) => (
              <div key={index}>
                <ProjectSection
                  num={index}
                  lastLength={projectData}
                  data={data}
                />
              </div>
            ))}
          </section>
          <Testimonials
            title={`Clients' word on our <span class='h1-span'>UI UX Services</span>`}
          />
          <Cta data={cta2} />
          <CommonServiceComponent
            data={commonContent5}
            condition
            mode={"light"}
          />
          <ServiceTab tabs={tabs} mode={"split"} />
          <Faq
            Questions={Questions}
            section
            des={faqDes}
            link
            faqData={faqData}
          />
          <Cta mode="grey" data={cta3} />
          {/* <KnowMoreSection /> */}
        </div>
      </Layout>
    </>
  );
};

export default ServiceDetail;

export const Head = () => (
  <Seo
    title="UI UX Designing Company | UI UX Design Solutions"
    description="Get ahead in the digital world with the best UI UX design company. Our UI UX design services focus on creativity, functionality, and user engagement."
  />
);
